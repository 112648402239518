<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Navigation
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-model-form v-if="$me.getUser()" :id="$me.getUser().user_id" module="userModule">
                <template v-slot:default="{ model, refreshModel }">
                    <v-section>
                        <v-section-heading>
                            <template v-slot:default>
                                {{ model.name }} - {{ $route.meta.title }}
                            </template>

                            <template v-slot:description>
                                {{ model.email }}
                            </template>

                            <template v-slot:actions>
                                <div class="block lg:hidden">
                                    <v-button color="primary" @click.stop="toggleSidebar">
                                        <i class="fa fa-list-ul" />
                                    </v-button>
                                </div>
                            </template>
                        </v-section-heading>

                        <v-ghost :user="model" @user-updated="refreshModel" />
                    </v-section>
                </template>
            </v-model-form>
        </template>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            menu: [
                {
                    label: "Summary",
                    route: { name: "me.profile" },
                    description: "Manage your basic information and contact details.",
                    icon: "fa fa-id-card",
                },
                {
                    label: "Notifications",
                    route: { name: "me.notifications" },
                    description: "All the notifications available to the user.",
                    icon: "fa fa-bell",
                },
                {
                    label: "Security",
                    route: { name: "me.security" },
                    description: "Manage the security options of your user account.",
                    icon: "fa fa-fingerprint",
                },
            ],
        };
    },
    created() {
        if (this.$me.hasPermission("users.payout-information.update")) {
            this.menu = this.menu.concat([{
                label: "Payout Information",
                route: { name: "me.payout-information" },
                description: "Manage information on how to receive your payouts.",
                icon: "fab fa-paypal",
            }]);
        }
    },
};
</script>
